.t-pale-blue {
  background-color: map_get($app-colors, "blue--pale");
}

.t-pale-green {
  background-color: map_get($app-colors, "green--pale");
}

.t-pale-orange {
  background-color: map_get($app-colors, "orange--pale");
}

.t-pale-purple {
  background-color: map_get($app-colors, "purple--pale");
}

.t-orange {
  background-color: map_get($app-colors, "orange");
}

.t-blue {
  background-color: map_get($app-colors, "blue");
}

.t-green {
  background-color: map_get($app-colors, "green");
}

.t-white {
  background-color: map_get($app-colors, "neutral--100");
}

.t-grey {
  background-color: map_get($app-colors, "neutral--200");
}
