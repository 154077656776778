.order-details {
  margin: 0 auto;
  max-width: 860px;
}

.order-details__content-wrapper {
  background-color: map-get($app-colors, 'neutral--100');
  box-shadow: 0 0 13px 0 rgb(map-get($app-colors, 'neutral--900'), .15);
  padding: ($spacer * 2) $spacer;

  @include media-breakpoint-up(md) {
    padding: $spacer * 2;
  }

  @include media-breakpoint-up(lg) {
    padding: $spacer * 3;
  }
}

.order-details__address {
  color: #000;
}

.order-details__disclaimer {
  font-style: italic;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: map-get($app-colors, 'neutral--700');
  max-width:28.5rem
}

.order-details__status {
  padding: 0rem 1.25rem;
  background-color: map-get($app-colors, 'neutral--850');
  color: map-get($app-colors, 'neutral--100');
  display:inline-block;
  font-weight: 600;

  &.order-details__status--due {
    background-color: map-get($app-colors, 'red--error');
    color: map-get($app-colors, 'neutral--100');
  }

  &.order-details__status--fully-paid {
    background-color: map-get($app-colors, 'green--dark');
    color: map-get($app-colors, 'neutral--100');
  }

}

.order-details__line-items {
  margin-bottom: $spacer * 2;
  width: 100%;

  hr {
    margin: ($spacer * .5) 0 !important;
  }

}

.order-details__line-item {
  line-height: 1;

  .order-details__line-item-name {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .order-details__line-item-description {
    color: map_get($app-colors, "neutral--700");
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding-top: $spacer;
  }

  .order-details__line-item-amount {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 0;
  }
}

.order-details__line-item.order-details__line-item--light {

  .order-details__line-item-name {
    color: map_get($app-colors, "neutral--700");
    font-size: 1.125rem;
    font-weight: 400;
  }

  .order-details__line-item-amount {
    font-size: 1.125rem;
    font-weight: 500;
  }
}

.order-details__line-item.order-details__line-item--subtotal {
  .order-details__line-item-name {
  }
  .order-details__line-item-amount {
    font-size: 1.125rem;
  }
}

.order-details__line-item.order-details__line-item--total {
  .order-details__line-item-name {
  }
  .order-details__line-item-amount {
    font-size: 2rem;
    font-weight: 900;
  }
}


.order-details__payment-method-details {
  background-color: map_get($app-colors, "neutral--200");
  padding: $spacer;
}

.order-details__text--grey {
    color: map_get($app-colors, "neutral--700");
    
    p {
      color: map_get($app-colors, "neutral--700");
    }
}

// ============================================================================================
// Embedded Component Overrides
.order-details .hero-banner {
  .hero-banner__text-content-wrapper {
    padding-top: $spacer * 4;

    @include media-breakpoint-up(md) {
      padding-left: $spacer * 2;
    }

    @include media-breakpoint-up(lg) {
      padding-left: $spacer * 3;
    }
  }
}
